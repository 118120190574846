<template>
  <g>
    <svg:style>
      .cls-1, .cls-10, .cls-11, .cls-12, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-10, .cls-11, .cls-12, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#glass-pattern);
      }

      .cls-11 {
        fill: url(#inox-gradient-2);
      }

      .cls-12 {
        fill: url(#inox-gradient-3);
      }

    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 666.9"
                    :y1="doorTopHeight1 + 7354.61"
                    :x2="doorLeftWidth1 + 736.51"
                    :y2="doorTopHeight1 + 7354.61"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 85.2"
                    :y1="doorTopHeight1 + 124.27"
                    :x2="doorLeftWidth1 + 85.2"
                    :y2="doorTopHeight1 + 91.37"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 54.66"
                    :y1="doorTopHeight1 + 124.27"
                    :x2="doorLeftWidth1 + 54.66"
                    :y2="doorTopHeight1 + 91.37"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-3"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 85.2"
                    :y1="doorTopHeight1 + 157.53"
                    :x2="doorLeftWidth1 + 85.2"
                    :y2="doorTopHeight1 + 126.21"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-4"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 54.66"
                    :y1="doorTopHeight1 + 157.53"
                    :x2="doorLeftWidth1 + 54.66"
                    :y2="doorTopHeight1 + 126.21"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-5"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 686.44"
                    :y1="doorTopHeight1 + 7339.13"
                    :x2="doorLeftWidth1 + 686.44"
                    :y2="doorTopHeight1 + 7302.16"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="glass-gradient-6"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 716.98"
                    :y1="doorTopHeight1 + 7339.13"
                    :x2="doorLeftWidth1 + 716.98"
                    :y2="doorTopHeight1 + 7302.12"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 701.68"
                    :y1="doorTopHeight1 + 7496.18"
                    :x2="doorLeftWidth1 + 701.68"
                    :y2="doorTopHeight1 + 7468.15"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 701.68"
                    :y1="doorTopHeight1 + 7454.28"
                    :x2="doorLeftWidth1 + 701.68"
                    :y2="doorTopHeight1 + 7426.24"
                    xlink:href="#handle-gradient"/>

    <g id="С5">
      <path id="inox"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 666.9},${doorTopHeight1 + 7310.42}
            s10,0.6,20.1-7.65
            c7.71-6.09,14.93-5.7,15-5.7
            s6.31-.62,15.53,6
            c10.73,7.87,19,7.35,19,7.35
            v101.74
            H${doorLeftWidth1 + 666.9}
            V${doorTopHeight1 + 7310.42}
            Z
            `"
            transform="translate(-631.78 -7249.7)"/>
      <rect id="Glass"
            class="cls-5"
            :x="doorLeftWidth1 + 70.9"
            :y="doorTopHeight1 + 91.37"
            width="28.6"
            height="32.89"/>
      <rect id="Glass-2"
            data-name="Glass"
            class="cls-6"
            :x="doorLeftWidth1 + 40.36"
            :y="doorTopHeight1 + 91.37"
            width="28.6"
            height="32.89"/>
      <rect id="Glass-3"
            data-name="Glass"
            class="cls-7"
            :x="doorLeftWidth1 + 70.9"
            :y="doorTopHeight1 + 126.21"
            width="28.6"
            height="31.32"/>
      <rect id="Glass-4"
            data-name="Glass"
            class="cls-8"
            :x="doorLeftWidth1 + 40.36"
            :y="doorTopHeight1 + 126.21"
            width="28.6"
            height="31.32"/>
      <path id="Glass-5"
            data-name="Glass"
            class="cls-9"
            :d="`
            M${doorLeftWidth1 + 700.74},${doorTopHeight1 + 7339.13}
            v-37
            c-2,.17-6.39,1-11.53,4.8
            a33.06,33.06,0,0,1-17.07,7.56
            v24.61
            h28.6
            Z
            `"
            transform="translate(-631.78 -7249.7)"/>
      <path id="Glass-6"
            data-name="Glass"
            class="cls-10"
            :d="`
            M${doorLeftWidth1 + 702.68},${doorTopHeight1 + 7339.13}
            h28.6
            v-24
            s-7-1.26-16.14-7.95
            c-6.4-4.34-10.77-5-12.46-5.09
            v37
            Z
            `"
            transform="translate(-631.78 -7249.7)"/>
      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-11"
            :d="`
            M${doorLeftWidth1 + 666.79},${doorTopHeight1 + 7468.15}
            v28h69.78
            v-28
            H${doorLeftWidth1 + 666.79}
            Z
            m65,23.07
            H${doorLeftWidth1 + 671.61}
            V${doorTopHeight1 + 7473}
            h60.14
            v18.25
            Z
            `"
            transform="translate(-631.78 -7249.7)"/>
      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-12"
            :d="`
            M${doorLeftWidth1 + 666.79},${doorTopHeight1 + 7426.24}
            v28h69.78
            v-28
            H${doorLeftWidth1 + 666.79}
            Z
            m65,23.07
            H${doorLeftWidth1 + 671.61}
            v-18.25
            h60.14
            v18.25
            Z
            `"
            transform="translate(-631.78 -7249.7)"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
